// @flow
import { isProd } from '../config'

const ID = '2341900765918529'
const pixel = isProd ? window.fbq : (...args) => console.log(...args)

const fbPixel = {
    init: () => pixel('init', ID),
    pageView: () => pixel('track', 'PageView'),
}

export default fbPixel