// @flow
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prefer-stateless-function, max-classes-per-file */

import * as React from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Images } from '@/constants'
import styles from './styles.scss'

type ModalProps = {|
  ariaLabel?: string,
  visible: boolean, // eslint-disable-line react/no-unused-prop-types
  children: React.Node,
|}



class ModalContent extends React.Component<ModalProps> {
  static defaultProps = {
    ariaLabel: '',
  }

  render() {
    const { ariaLabel, children } = this.props
    return (
      <div aria-label={ariaLabel}>
        <div className={styles.modalContainer} />
        {children}
      </div>
    )
  }
}

export class Modal extends React.Component<ModalProps> {
  static defaultProps = {
    ariaLabel: '',
  }

  targetRef: ReactRef<Modal> = React.createRef()

  targetElement = null

  componentDidMount() {
    this.targetElement = this.targetRef.current
  }

  componentDidUpdate(prevProps: ModalProps) {
    const { visible } = this.props
    if (!prevProps.visible && visible && document.body) {
      // $FlowFixMe
      disableBodyScroll(this.targetElement)
    } else if (prevProps.visible && !visible && document.body) {
      // $FlowFixMe
      enableBodyScroll(this.targetElement)
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  render() {
    const { visible } = this.props
    if (visible) {
      return <ModalContent ref={this.targetRef} {...this.props} />
    }
    return null
  }
}

type ContentModalProps = {
  requestClose: () => void,
  title: string,
  leftAlign?: boolean,
  children: React.Node,
  button?: React.Node,
  ariaLabel: string,
  visible: boolean,
}

const ContentModal = (props: ContentModalProps) => {
  const { button, title, leftAlign, requestClose, children, visible, ariaLabel } = props
  const buttonComp = button ? <div className={styles.button}>{button}</div> : null
  return (
    <Modal visible={visible} ariaLabel={ariaLabel}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h4 className={leftAlign ? styles.leftAlign : ''}>{title}</h4>
          <button className={styles.closeButton} type="button" onClick={requestClose}>
            <div className={styles.orangeCircle}>
              <img src={Images.close} alt="close" />
            </div>
          </button>
        </div>
        <div className={styles.modalChildren}>{children}</div>
        {buttonComp}
      </div>
    </Modal>
  )
}

ContentModal.defaultProps = {
  leftAlign: false,
  button: null,
}

export default ContentModal
