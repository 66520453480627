// @flow
import * as React from 'react'
import { withRouter } from 'react-router'

type ScrollToTopProps = {
  location: Location,
  children: React.Node,
}

class ScrollToTop extends React.Component<ScrollToTopProps> {
  componentDidUpdate(prevProps: ScrollToTopProps) {
    const { location } = this.props
    const { location: prevLocation } = prevProps
    if (location.pathname !== prevLocation.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}

export default withRouter<*>(ScrollToTop)
