// @flow

import React from 'react'
import classNames from 'classnames'
import { Anchor } from '@/components'
import Accessibility from '@/accessibility'
import Strings, { Images, FooterLinks, FooterQuickLinks,AltText, Routes, SocialLinks } from '@/constants'
import styles from './styles.scss'
import anchorStyles from '@/components/anchor/styles.scss'

const { logo } = Images
const { facebook: fbAlt, instagram: igAlt, youtube: ytAlt } = AltText
const { facebook, instagram, youtube } = SocialLinks

const Footer = () => {
  const renderTermLinks = () => {
    const footerLinks = [
      <a className={anchorStyles.anchor} href={Routes.ContactUs} key={Strings.contactUs}>
        {Strings.contactUs}
      </a>,
    ]
    const basicLinks = FooterLinks.map(obj => (
      <Anchor
        key={obj.name}
        ariaLabel={obj.name}
        label={obj.name}
        path={obj.path}
        blank={obj.blank}
      />
    ))

    return footerLinks.concat(basicLinks)
  }

  const renderQuickLinks = () => FooterQuickLinks.map(obj => (
      <Anchor
        key={obj.name}
        ariaLabel={obj.name}
        label={obj.name}
        path={obj.path}
        blank={obj.blank}
      />
    ))

  return (
    <section className={styles.footer} aria-label={Accessibility.App.Footer}>
      <div className={styles.footerCenterContainer}>
        <div className={styles.leftContainer}>
          <img className={styles.logo} src={logo} alt="logo" />
          <div className={styles.footerIcons}>
            <a href={instagram} target="_blank" rel="noopener noreferrer">
              <img src={Images.instagram} alt={igAlt} />
            </a>
            <a href={facebook} target="_blank" rel="noopener noreferrer">
              <img src={Images.facebook} alt={fbAlt} />
            </a>
            <a href={youtube} target="_blank" rel="noopener noreferrer">
              <img src={Images.youtube} alt={ytAlt} />
            </a>
          </div>
          <div className={styles.copyright}>
            &copy;
            {new Date().getFullYear()} Copyright by SVH Travel.
            <br /> All rights reserved.
          </div>
        </div>

        <div className={styles.rightContainer}>
          <div className={styles.links}>
            <p>Quick Links</p>
            <div className={styles.quickLinks}>{renderQuickLinks()}</div>
          </div>
          <div className={styles.links}>
            <p>Terms and Policies</p>
            <div className={styles.quickLinks}>{renderTermLinks()}</div>
          </div>

          <div className={classNames(styles.copyright, styles.secondCopy)}>
            &copy;
            {new Date().getFullYear()} Copyright by SVH Travel.
            <br /> All rights reserved.
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
