// @flow

import * as React from 'react'
import Accessibility from '@/accessibility'
import Strings, { Images, NavLinks, Routes } from '@/constants'
import { Anchor, Sidebar } from '@/components'
import styles from './styles.scss'

const { CallUs, Nav, Logo } = Accessibility.App
const { logo, hamburger } = Images

type StateProps = {||}

type DispatchProps = {||}

type NavbarProps = {|
  ...StateProps,
  ...DispatchProps,
|}

type NavbarState = {|
  sidebarOpen: boolean,
|}

export class Navbar extends React.Component<NavbarProps, NavbarState> {
  constructor(props: NavbarProps) {
    super(props)
    this.state = { sidebarOpen: false }
  }

  toggleSidebar = () => {
    this.setState(prevState => ({ sidebarOpen: !prevState.sidebarOpen }))
  }

  renderNavLinks = (): React.Node =>
    NavLinks.map(obj => (
      <Anchor key={obj.name} ariaLabel={obj.name} label={obj.name} path={obj.path} blank={obj.blank} />
    ))

  render() {
    const { sidebarOpen } = this.state

    return (
      <nav className={styles.navbar} aria-label={Nav}>
        <Sidebar
          sidebarOpen={sidebarOpen}
          ariaLabel={Accessibility.Components.Sidebar}
          toggle={this.toggleSidebar}
        />
        <button onClick={this.toggleSidebar} type="button" className={styles.menuButton}>
          <img className={styles.menuIcon} src={hamburger} alt="menu icon" />
        </button>
        <a aria-label={Logo} href={Routes.wixHome} className={styles.logoContainer}>
          <img className={styles.logo} src={logo} alt="logo" />
        </a>
        <section className={styles.navLinks}>{this.renderNavLinks()}</section>
        <a href={Routes.ContactUs} className={styles.callUs} aria-label={CallUs}>
          <img src={Images.phone} alt={Strings.callUs} />
        </a>
      </nav>
    )
  }
}

export default Navbar
