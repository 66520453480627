// @flow
import { put } from 'redux-saga/effects'
import { ActionCreators } from '@/redux'
import {
  searchPropertiesRequest,
  checkPriceRequest,
  createBookingRequest,
  checkItineraryRequest,
  cancelBookingRequest,
  payBookingRequest
} from '@/redux/api-monads'

type ActionType<T> = {
  type: String,
  payload: T
}

export function* searchProperties(action: ActionType<SearchPayload>): GeneratorType {
  yield put(ActionCreators.api.makeRequest.dispatch(searchPropertiesRequest(action.payload)))
}

export function* checkPrice(action: ActionType<CheckPricePayload>): GeneratorType {
  yield put(ActionCreators.api.makeRequest.dispatch(checkPriceRequest(action.payload)))
}

export function* createBooking(action: ActionType<CreateBookingPayload>): GeneratorType {
  yield put(ActionCreators.api.makeRequest.dispatch(createBookingRequest(action.payload)))
}

export function* payBooking(action: ActionType<PayBookingRequest>): GeneratorType {
  yield put(ActionCreators.api.makeRequest.dispatch(payBookingRequest(action.payload)))
}

export function* checkItinerary(action: ActionType<{itineraryId: string}>): GeneratorType {
  yield put(ActionCreators.api.makeRequest.dispatch(checkItineraryRequest(action.payload)))
}

export function* cancelBooking(action: ActionType<{itineraryId: string}>): GeneratorType {
  yield put(ActionCreators.api.makeRequest.dispatch(cancelBookingRequest(action.payload)))
}

export function* cancelBookingSuccess(action: ActionType<CancelBookingDataType>): GeneratorType {
  // $FlowFixMe
  yield put(ActionCreators.api.makeRequest.dispatch(checkItineraryRequest({itineraryId: action.payload.data.itinerary_id})))
}

export default {
  searchProperties
}
