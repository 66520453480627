// @flow
import packageDotJSON from '../../package.json'

const { sha, static_path } = window.svhTravel || {}

const debug: ConfigType = {
  logrocket: null,
  sentry: null,
  staticPath: 'http://127.0.0.1:8000/static',
  apiURL: 'http://127.0.0.1:8000/api/v1/',
  googleAnalytics: {
    measurementID: '',
  },
  hotjar: null,
  version: packageDotJSON.version,
  stripePubKey:
    'pk_test_51K7Q9NFe9DbAyTJ8edru9kljQfSdYP7VouodJ9OprfThge28utV9fEyDRE0iKollfdwN8F9pPzD3xTWPo1LdRNig000bSQsEBJ',
}

const dev: ConfigType = {
  logrocket: null,
  sentry: {
    token: 'https://0bd70366d0e044c1bca0adc35e1e0a52@sentry.io/1389318',
  },
  staticPath: static_path ? static_path.slice(0, -1) : '',
  apiURL: 'https://all-inclusive-vacations-dev.herokuapp.com/api/v1/',
  googleAnalytics: {},
  hotjar: null,
  version: packageDotJSON.version,
  stripePubKey:
    'pk_test_51K7Q9NFe9DbAyTJ8edru9kljQfSdYP7VouodJ9OprfThge28utV9fEyDRE0iKollfdwN8F9pPzD3xTWPo1LdRNig000bSQsEBJ',
}

const staging: ConfigType = {
  logrocket: {
    url: '60a4e3/all-inclusive-vacations',
    show_reporting_button: true,
    sha,
  },
  sentry: {
    token: 'https://0bd70366d0e044c1bca0adc35e1e0a52@sentry.io/1389318',
  },
  staticPath: static_path ? static_path.slice(0, -1) : '',
  apiURL: 'https://all-inclusive-vacations-stg.herokuapp.com/api/v1/',
  googleAnalytics: {
    measurementId: 'UA-68727809-2',
  },
  hotjar: null,
  version: packageDotJSON.version,
  stripePubKey:
    'pk_test_51K7Q9NFe9DbAyTJ8edru9kljQfSdYP7VouodJ9OprfThge28utV9fEyDRE0iKollfdwN8F9pPzD3xTWPo1LdRNig000bSQsEBJ',
}

const production: ConfigType = {
  logrocket: {
    url: '60a4e3/all-inclusive-vacations',
    show_reporting_button: false,
    sha,
  },
  sentry: {
    token: 'https://0bd70366d0e044c1bca0adc35e1e0a52@sentry.io/1389318',
  },
  staticPath: static_path ? static_path.slice(0, -1) : '',
  apiURL: 'https://all-inclusive-vacations.herokuapp.com/api/v1/',
  googleAnalytics: {
    measurementId: 'UA-68727809-1',
  },
  hotjar: {
    id: 1817352,
    version: 6,
  },
  stripePubKey:
    'pk_live_51K7Q9NFe9DbAyTJ8EJrxq0fHOGIP3X2cAx35oDdfkc91N8KMmfo5GEGwUaabt7vClCXpWLYakBnZIy46LJ4ErnOI00tianaSlV',
}

const configs = {
  debug,
  dev,
  staging,
  production,
}

const environment = configs[process.env.NODE_ENV || 'debug']

export const isProd = process.env.NODE_ENV === 'production'

if (!environment) {
  /* eslint-disable */
  console.error('No config file found.  Defaulting to debug')
  /* eslint-enable */
}

const config: ConfigType = environment || debug

export default config
