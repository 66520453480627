// @flow
import publicIp from 'public-ip'
import moment from 'moment'
import classnames from 'classnames';
import { isString, get, find } from 'lodash'
import { ADULTS_ONLY, ALL_INCLUSIVE_RATE, PETS_ALLOWED } from '@/constants/eps'
import { Property } from '@/models'
import type { ImageDataType, RoomDataType, RateDataType } from '@/models/property'

export function debounce(func: any, delay: number=300) {
  let timer;
  return (...args: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, delay)
  }
}

const KEY_VALUE = 'keyValue'
const mapOptions = (
  list: Array<Object | string> | Object,
  legend: { label: string, value: string } | typeof KEY_VALUE = mapOptions.default,
): OptionsType<string> => {
  if (legend === mapOptions.keyValue) {
    if (Array.isArray(list)) {
      throw new Error('keyValue mapOptions requires an object not an array.')
    }
    return Object.keys(list).map(k => ({
      value: list[k],
      label: k,
    }))
  }

  if (!Array.isArray(list) || typeof legend === 'string') {
    throw new Error(
      'keyValue mapOptions requires an array not an object. Legend should be an object with string value for the keys "labe" and "value"',
    )
  }
  const { label, value } = legend
  return list.map(d => ({
    value: typeof d === 'object' ? d[value] : d,
    label: typeof d === 'object' ? d[label] : d,
  }))
}

mapOptions.location = { label: 'name', value: 'code' }
mapOptions.destination = { label: 'location', value: 'airportCode' }
mapOptions.default = { label: '', value: '' }
mapOptions.keyValue = KEY_VALUE

export const mapToQueryString = (object: { [string]: any }) =>
  Object.keys(object)
    .reduce(
      (acc, cur) => [...acc, `${encodeURIComponent(cur)}=${encodeURIComponent(object[cur])}`],
      [],
    )
    .join('&')

export const mapQueryStringToObject = (qs: string) => {
  const qsObj = {}
  const qsArray = qs.replace('?', '').split('&')
  qsArray.forEach(s => {
    const [key, value] = s.split('=')
    if (key && value) {
      qsObj[key] = value
    }
  })
  return qsObj
}

export const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export const jsKey = (key: string) => `${key[0].toLowerCase()}${key.slice(1)}`

export const capitalizeFirstLetter = (word: string) =>
  word
    ? word
        .split(' ')
        .map(w => `${w[0].toUpperCase()}${w.slice(1).toLowerCase()}`)
        .join(' ')
    : word

export const validate = (
  obj: *,
  validators: { [string]: (value: any, obj: *) => * },
): { [string]: * } => {
  const errors = Object.keys(obj).reduce((acc, curr) => {
    if (validators[curr]) {
      acc[curr] = validators[curr](obj[curr], obj)
    }
    return acc
  }, {})
  return errors
}

export const localizePrice = (price: number | string) => {
  let localizedPrice = price
  if (isString(localizedPrice)) {
    // $FlowFixMe
    localizedPrice = Number(price.replace(/[^\d.-]/g, ''))
  }
  return localizedPrice.toLocaleString(undefined, {
    // Undefined prevents manual setting of locale
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const isAdultsOnly = (property: Property) => (
  get(property.metadata || {}, 'name', '').toLowerCase().includes('adults-only') ||
  get(property.metadata || {}, 'name', '').toLowerCase().includes('adults only') ||
  get(property.metadata || {}, `attributes.metadata.${ADULTS_ONLY[0]}`, false) ||
  get(property.metadata || {}, `attributes.metadata.${ADULTS_ONLY[1]}`, false)
)

export const arePetsAllowed = (property: Property) => get(property.metadata || {}, `attributes.pets.${PETS_ALLOWED}`, false)

export const isRateAllInclusive = (rate: RateDataType) => Object.keys(rate.amenities).includes(ALL_INCLUSIVE_RATE)

export const getRateLowestPrice = (rate: RateDataType) => Object.values(get(rate, 'occupancy_pricing', {}))
  .reduce((lowest, pricing) => {
    const currency = get(pricing, 'totals.inclusive.billable_currency.currency', null)
    if (!currency || currency !== 'USD') return lowest
    if (!lowest) return pricing
    const current = parseFloat(get(pricing, 'totals.inclusive.billable_currency.value', Infinity))
    const accumulated = parseFloat(get(lowest, 'totals.inclusive.billable_currency.value'))
    return current < accumulated ? pricing : lowest
  }, null)

export const getLowestRoomRate = (room: RoomDataType): RateDataType => get(room, 'rates', [])
  .reduce((low, rate) => {
    if (!low) return rate
    const current = parseFloat(get(getRateLowestPrice(rate), 'totals.inclusive.billable_currency.value', Infinity))
    const accumulated = parseFloat(get(getRateLowestPrice(low), 'totals.inclusive.billable_currency.value'))
    return current < accumulated ? rate : low
  })

export const getLowestRoomsRate = (rooms: Array<RoomDataType>) => rooms
  .reduce((acc, room) => {
    const currentRate = getLowestRoomRate(room)
    if (!acc) return currentRate
    const current = parseFloat(get(getRateLowestPrice(currentRate), 'totals.inclusive.billable_currency.value', Infinity))
    const accumulated = parseFloat(get(getRateLowestPrice(acc), 'totals.inclusive.billable_currency.value'))
    return current < accumulated ? currentRate : acc
  }, null)

export const sortByPricing = (a: RoomDataType, b: RoomDataType) => {
  const aLowestPricing = getRateLowestPrice(getLowestRoomRate(a))
  const bLowestPricing = getRateLowestPrice(getLowestRoomRate(b))
  const aPrice = parseFloat(get(aLowestPricing, 'totals.inclusive.billable_currency.value'))
  const bPrice = parseFloat(get(bLowestPricing, 'totals.inclusive.billable_currency.value'))

  return aPrice - bPrice
}

export const getAverageNightlyRate = (rate: any) => {
  let sum = 0
  const lowestPrice = get(rate, 'nightly') ? rate : getRateLowestPrice(rate)
  const baseRates = get(lowestPrice, 'nightly', []).map(night =>
    find(
      Object.keys(night).map(key => night[key]),
      { type: 'base_rate' },
    ),
  )

  baseRates.forEach(baseRate => {
    sum += parseFloat(get(baseRate, 'value', '0'))
  })
  return Math.round(sum / baseRates.length)
}

export const RefundableStatuses = {
  REFUNDABLE: 1,
  PARTIALLY_REFUNDABLE: 2,
  NON_REFUNDABLE: 3
}

/* eslint-disable no-nested-ternary */
export const isRoomRefundable = (room: RoomDataType) => room
  ? room.rates.every(rate => rate?.refundable)
    ? RefundableStatuses.REFUNDABLE
    : room.rates.every(rate => !rate?.refundable)
      ? RefundableStatuses.NON_REFUNDABLE
      : RefundableStatuses.PARTIALLY_REFUNDABLE
  : null
/* eslint-enable no-nested-ternary */

export const getRefundableStatus = (rooms: Array<RoomDataType>) => {
  const roomRefundableStatuses = rooms.map(room => isRoomRefundable(room))
  // eslint-disable-next-line no-nested-ternary
  return roomRefundableStatuses && roomRefundableStatuses.length > 0
    // eslint-disable-next-line no-nested-ternary
    ? roomRefundableStatuses.every(s => s === RefundableStatuses.REFUNDABLE)
      ? RefundableStatuses.REFUNDABLE
      : !roomRefundableStatuses.every(s => s === RefundableStatuses.NON_REFUNDABLE)
        ? RefundableStatuses.PARTIALLY_REFUNDABLE
        : RefundableStatuses.NON_REFUNDABLE
    : null
}

export const rateAllowsPayLater = (rate: RateDataType) => {
  if (!rate.refundable) return false

  const soonest_penalty = (rate?.cancel_penalties || []).reduce((acc, penalty) => {
    if (!acc) return moment(penalty.start)
    if (acc.isBefore(moment(penalty.start))) return acc
    return moment(penalty.start)
  }, null)

  if (!soonest_penalty) return false

  return soonest_penalty.subtract(4, 'w').isAfter(moment())
}

export const roomAllowsPayLater = (room: RoomDataType) => (room?.rates || []).some(rate => rateAllowsPayLater(rate))

export const getPayLaterStatus = (rooms: Array<RoomDataType>) => rooms.some(room => roomAllowsPayLater(room))

export const getImageUrl = (image: ImageDataType) => {
  const sizes = [1000, 350, 200, 70]
  return sizes.reduce((acc, size) => acc || get(image, `links.${size}px.href`, null), null)
}

export const getRoomImages = (images: Array<ImageDataType>) => {
  const sizes = [350, 200, 70]
  if (!images || images?.length === 0) return []
  // $FlowFixMe
  return images.map(image => sizes
    .filter(size => get(image, `links.${size}px.href`, false))
    .reduce((acc, size) => acc || { href: get(image, `links.${size}px.href`), caption: image.caption }, null)
  )
}

export const dataFromQueryString = (query: string) =>
  // $FlowFixMe
  Object.fromEntries(new URLSearchParams(query).entries())

export const camelCaseToSnakeCase = (obj: Object) => Object
  .entries(obj)
  .reduce((acc, [key, value]) => {
    const newKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    // eslint-disable-next-line no-nested-ternary
    const newValue = Array.isArray(value) && value.length > 0 && typeof(value[0]) === 'object'
      ? value.map(e => camelCaseToSnakeCase(e))
      : typeof(value) === 'object'
        ? camelCaseToSnakeCase(value)
        : value
    return {...acc, [newKey]: newValue}
  }, {})

export const getPublicIp = async () => publicIp.v4({fallbackUrls: ['https://ifconfig.co/ip']})

export const numPad = (num: number, size: number) => {
  let n = num.toString()
  while (n.length < size) n = `0${n}`
  return n
}

export const objectToBase64 = (data: Object): string => {
  try {
    return data ? encodeURIComponent(btoa(JSON.stringify(data))) : ''
  } catch {
    return ''
  }
}

export const base64ToObject = (query: string): Object => {
  try {
    return query ? JSON.parse(atob(decodeURIComponent(query))) : {}
  } catch {
    return {}
  }
}

export { classnames, mapOptions }

export { default as useCountdown } from './use-countdown'
export { default as useSwipe } from './use-swipe'

export default mapOptions
