// @flow
import * as React from 'react'
import styles from './styles.scss'
// $FlowFixMe
import config from '@/config'

const Version = () => {
  if (config.version) {
    return (
      <div className={styles.version}>
        <span>{config.version}</span>
      </div>
    )
  }
  return null
}

export default Version
