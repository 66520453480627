// @flow
import { dispatchReducer, runReducers } from '@/helpers/dispatch-and-reduce'

export const ACTIONS = {
  MAKE_EVERY_REQUEST: 'MAKE_EVERY_REQUEST',
  MAKE_LATEST_REQUEST: 'MAKE_LATEST_REQUEST',
}

export const INITIAL_STATE = {}

export function makeRequest<StateType, PayloadDataType>(
  state: StateType,
  // $FlowFixMe
  { payload }: ApiPayloadType<PayloadDataType>, // eslint-disable-line
): StateType {
  return {
    ...state,
  }
}

export const reduxSet = {
  makeRequest: dispatchReducer<
    $Values<StoreState>,
    {
      url: ApiService$Keys,
      data?: *,
      storeName: StoreState$Keys,
      reducerKey: string,
      preSendReducerKey?: ?string,
    },
  >(ACTIONS.MAKE_EVERY_REQUEST, makeRequest),
  makeLatestRequest: dispatchReducer<
    $Values<StoreState>,
    {
      url: ApiService$Keys,
      data?: *,
      storeName: StoreState$Keys,
      reducerKey: string,
      preSendReducerKey?: ?string,
    },
  >(ACTIONS.MAKE_LATEST_REQUEST, makeRequest),
}

export const reducer = (
  state: APIStoreState = { ...INITIAL_STATE },
  action: StandardAction,
): APIStoreState => runReducers(state, reduxSet, action)
