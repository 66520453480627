// @flow
import BaseModel from './base-model'
import Location from './location'
import Property from './property'

const models = {
  BaseModel,
  Property,
  Location,
}

export function modelMapper(responseType: string, payload: { data: Object | Array<Object> }) {
  const response = {}
  if (!(responseType in models)) return payload
  
  response.data = (Array.isArray(payload.data))
    // $FlowFixMe
    ? payload.data.map(modelDataType => new models[responseType](modelDataType))
    : new models[responseType](payload.data)
  
  return response
}

export const ModelTypes: { [string]: string } = Object.keys(models).reduce((acc, curr: string) => {
  acc[curr] = curr
  return acc
}, {})

export {
  BaseModel,
  Location,
  Property,
}

export default models