// @flow
import { put } from 'redux-saga/effects'
import { ActionCreators } from '@/redux'
import { getLocationsRequest } from '@/redux/api-monads'

function* startUp(): GeneratorType {
  yield put(ActionCreators.api.makeRequest.dispatch(getLocationsRequest()))
}

export { startUp }
export default startUp
