// @flow

import React from 'react'
import styles from './styles.scss'
import Accessibility from '@/accessibility'
import { classnames } from '@/helpers'
import { Modal } from '../modal'

export const Loading = () => <div className={styles.spinner} />

export const DotLoading = ({ className }: { className: string }) => <div className={classnames(styles.loader, className)} />

type ModalLoadingProps = {
  visible: boolean,
}

export const ModalLoading = (props: ModalLoadingProps) => {
  const { visible } = props
  return (
    <Modal visible={visible} ariaLabel={Accessibility.Components.Loading}>
      <div className={styles.spinnerContainer}>
        <Loading />
      </div>
    </Modal>
  )
}

ModalLoading.defaultProps = {}

export default Loading
