// @flow

import React from 'react'
import styled, { css } from 'styled-components'
import { Anchor } from '@/components'
import Strings, { Images, FooterLinks, NavLinks, Routes } from '@/constants'
import styles from './styles.scss'
import Accessibility from '@/accessibility'
import events from '@/helpers/ga'

const { logo } = Images

type SidebarProps = {|
  sidebarOpen: boolean,
  ariaLabel: string,
  toggle: () => void,
|}

const Sidebar = styled.div`
  width: 100vw;
  position: absolute;
  top: -100vh;
  left: 0;
  transition: opacity 0.3s, top 0.4s ease;
  opacity: 0;
  ${props =>
    props.sidebarOpen &&
    css`
      top: 0;
      visibility: visible;
      opacity: 1;
    `}
`
const SidebarShadow = styled.div`
  position: absolute;
  visibility: hidden;
  transition: all 0.2s;
  ${props =>
    props.sidebarOpen &&
    css`
      transition: all 0.8s;
      visibility: visible;
    `}
`

const renderNavLinks = () =>
  NavLinks.concat(FooterLinks).map(obj => (
    <Anchor key={obj.name} ariaLabel={obj.name} label={obj.name} path={obj.path} />
  ))

class SidebarComponent extends React.Component<SidebarProps> {

  toggle = () => {
    const { sidebarOpen, toggle } = this.props
    if (sidebarOpen) {
      toggle()
    }
  }

  render() {
    const { sidebarOpen, ariaLabel } = this.props
    return (
      <>
        <Sidebar sidebarOpen={sidebarOpen} className={styles.sidebar} aria-label={ariaLabel}>
          <img className={styles.logo} src={logo} alt="logo" />
          <div className={styles.navLinks}>{renderNavLinks()}</div>
          <button onClick={this.toggle} type="button" className={styles.closeContainer}>
            <img className={styles.closeIcon} src={Images.close} alt="close icon" />
          </button>
          <div className={styles.buttonWrap}>
            <a
              href={Routes.ContactUs}
              onClick={events.clickCallUs}
              className={styles.callUs}
              aria-label={`${ariaLabel}/CallUs`}
            >
              {Strings.callUs}
            </a>
          </div>
        </Sidebar>
        <SidebarShadow
          className={styles.sidebarShadow}
          sidebarOpen={sidebarOpen}
          ariaLabel={Accessibility.Components.Sidebar}
        />
      </>
    )
  }
}

export default SidebarComponent
