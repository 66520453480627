// @flow
import Raven from 'raven-js'
import { delay, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import ApiService from '@/helpers/ApiService'
import { ActionCreators } from '@/redux'

const consoleError = error => {
  if (process.env.NODE_ENV !== 'production') {
    console.error(error) // eslint-disable-line no-console
  }
  const isAppleException = typeof error === 'object' && error && error?.name === 'AppleException'
  if (!isAppleException) {
    Raven.captureException(error)
  }
}

function* executeRequest(api: typeof ApiService, networkRequest): Array<Object> {
  const { url, data } = networkRequest
  let response = null
  let error = null
  try {
    // $FlowFixMe
    response = yield call(api[url], data)
    if (response.data.errors) {
      throw response.data.errors
    }
  } catch (e) {
    error = e
    consoleError(error)
  }
  // $FlowFixMe
  return [response, error]
}
// eslint-disable-next-line consistent-return
function* makeRequest(action: { type: string, payload: ApiPayloadType<*> }): GeneratorType {
  const {
    storeName,
    reducerKey,
    url,
    preSendReducerKey,
    data,
    nextUrl,
    repeatTillPayload,
  } = action.payload
  const errorKey = `${url}Error`
  const pendingKey = `${url}Pending`
  try {
    if (ActionCreators[storeName].startRequest) {
      yield put(
        ActionCreators[storeName].startRequest.dispatch({
          [errorKey]: null,
          error: null,
          [pendingKey]: true,
        }),
      )
    }
    if (preSendReducerKey) {
      yield put(ActionCreators[storeName][preSendReducerKey].dispatch(data))
    }
    const [resp, err] = yield call(executeRequest, ApiService, action.payload)
    if (resp) {
      // Setup all the successful response
      if (ActionCreators[storeName][reducerKey] === undefined) {
        console.error(`${reducerKey} reducer key is undefined.`) // eslint-disable-line no-console
      }
      yield put(ActionCreators[storeName][reducerKey].dispatch(resp.data))
      // We might get an error if the reservation was already created
      // but we will still have the info we need in the response
      if (
        (!err && nextUrl) ||
        (url === 'reservationCreate' && resp && resp?.data?.reservation?.uniqueId)
      ) {
        yield put(push(nextUrl))
      }
    } else if (err && repeatTillPayload) {
      yield delay(3000)
      // Deep clone
      const newAction = JSON.parse(JSON.stringify(action))
      newAction.payload.repeatTillPayload = repeatTillPayload - 1
      yield put(newAction)
    }

    if (ActionCreators[storeName].endRequest) {
      if (err?.response?.status === 503 && url !== 'reservationCreate') {
        // Heroku timeout try again
        yield delay(10000)
        yield makeRequest(action)
      } else {
        // It wasn't a timeout error so something actually went wrong
        const errorText = err?.data?.error || err
        yield put(
          ActionCreators[storeName].endRequest.dispatch({
            [errorKey]: errorText,
            [pendingKey]: false,
          }),
        )
      }
    }
  } catch (e) {
    consoleError(e)
    const errorText = e?.data?.error || e
    // Failure
    if (ActionCreators[storeName].endRequest) {
      yield put(
        ActionCreators[storeName].endRequest.dispatch({ error: errorText, [pendingKey]: false }),
      )
    }
  }
}

export { makeRequest }
export default makeRequest
