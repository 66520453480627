// @flow
export interface DispatchResponse<StateType, PayloadType> {
  type: string;
  dispatch: (payload?: PayloadType) => StandardAction;
  reducer: (state: StateType, value: PayloadType) => StateType;
}

export function dispatchReducer<StateType, PayloadType>(
  type: string,
  reducer: (state: StateType, value: PayloadType) => StateType,
): DispatchResponse<StateType, PayloadType> {
  return {
    type,
    reducer,
    dispatch: (payload?: PayloadType) => ({ type, payload }),
  }
}
type DispatcherSetType = { [any]: DispatchResponse<*, *> }

export const collectDispatchers = (reduxSets: {
  [any]: DispatchResponse<*, *> | DispatcherSetType,
}) => {
  const dispatchers = {}
  Object.keys(reduxSets).forEach(key => {
    dispatchers[key] = reduxSets[key].dispatch
  })
  return dispatchers
}

// State in should equal type of state out

export function runReducers(
  state: *,
  reducers: { [any]: DispatchResponse<*, *> | DispatcherSetType },
  action: StandardAction,
): * {
  let newState = { ...state }
  const reduce = reducerSet => {
    Object.keys(reducerSet).forEach(key => {
      const val = reducerSet[key]
      if (val.type === undefined) {
        newState = reduce(val)
      } else if (val.type === action.type) {
        // $FlowFixMe
        newState = val.reducer(newState, action.payload)
      }
    })
    return newState
  }
  return reduce(reducers)
}
