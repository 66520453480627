// @flow
/* eslint-disable no-undef */
import config from '../config'
import BedSVG from '../../../backend/svh_travel/static/images/bed-icon.svg'
import CheckSVG from '../../../backend/svh_travel/static/images/check-icon.svg'
import ClipboardSVG from '../../../backend/svh_travel/static/images/clipboard-icon.svg'
import FlightSVG from '../../../backend/svh_travel/static/images/flights-icon.svg'
import PersonSVG from '../../../backend/svh_travel/static/images/person-icon.svg'
import YouTubeSVG from '../../../backend/svh_travel/static/images/youtube-icon.svg'
import FourOFourTitle from '../../../backend/svh_travel/static/images/four-o-four-title.svg'

export const staticPath = (path: string) => `${config.staticPath}${path}`

const Images = {
  searchIcon: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/search.png')
    : staticPath('/images/search.png'),
  okIcon: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/ok-icon.png')
    : staticPath('/images/ok-icon.png'),
  logo: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/logo@2x.png')
    : staticPath('/images/logo@2x.png'),
  hamburger: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/hamburger.png')
    : staticPath('/images/hamburger.png'),
  danger: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/danger.png')
    : staticPath('/images/danger.png'),
  beach: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/beach.jpg')
    : staticPath('/images/beach.jpg'),
  cancun: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/cancun.png')
    : staticPath('/images/cancun.png'),
  searchPressed: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/search-pressed.png')
    : staticPath('/images/search-pressed.png'),
  arrowLeft: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/arrow-left-icon@2x.png')
    : staticPath('/images/arrow-left-icon@2x.png'),
  arrowLeftWhite: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/arrow-left-white@2x.png')
    : staticPath('/images/arrow-left-white@2x.png'),
  arrowRightWhite: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/arrow-right-white@2x.png')
    : staticPath('/images/arrow-right-white@2x.png'),
  carrotLeft: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/carrot-left-icon@2x.png')
    : staticPath('/images/carrot-left-icon@2x.png'),
  arrowRight: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/arrow-right-icon@2x.png')
    : staticPath('/images/arrow-right-icon@2x.png'),
  plus: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/plus@2x.png')
    : staticPath('/images/plus@2x.png'),
  minus: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/minus@2x.png')
    : staticPath('/images/minus@2x.png'),
  triangle: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/triangle@2x.png')
    : staticPath('/images/triangle@2x.png'),
  triangleUp: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/triangle-up@2x.png')
    : staticPath('/images/triangle-up@2x.png'),
  success: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/check@2x.png')
    : staticPath('/images/check@2x.png'),
  error: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/red-close@2x.png')
    : staticPath('/images/red-close@2x.png'),
  star: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/star@2x.png')
    : staticPath('/images/star@2x.png'),
  greyStar: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/greyStar@2x.png')
    : staticPath('/images/greyStar@2x.png'),
  facebook: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/facebook.png')
    : staticPath('/images/facebook.png'),
  youtube: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/youtube.png')
    : staticPath('/images/youtube.png'),
  instagram: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/instagram.png')
    : staticPath('/images/instagram.png'),
  whitePlane: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/plane-white@2x.png')
    : staticPath('/images/plane-white@2x.png'),
  orangePlane: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/plane-orange@2x.png')
    : staticPath('/images/plane-orange@2x.png'),
  whiteHotel: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/hotel-white@2x.png')
    : staticPath('/images/hotel-white@2x.png'),
  orangeHotel: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/hotel-orange@2x.png')
    : staticPath('/images/hotel-orange@2x.png'),
  delta: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/delta-air-lines-logo@2x.png')
    : staticPath('/images/delta-air-lines-logo@2x.png'),
  close: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/close@2x.png')
    : staticPath('/images/close@2x.png'),
  closeGrey: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/close-grey.png')
    : staticPath('/images/close-grey.png'),
  tennis: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/tennis@2x.png')
    : staticPath('/images/tennis@2x.png'),
  disco: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/disco@2x.png')
    : staticPath('/images/disco@2x.png'),
  caribbean: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/caribbean.jpg')
    : staticPath('/images/caribbean.jpg'),
  generationalBanner: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/generational-banner.jpg')
    : staticPath('/images/generational-banner.jpg'),
  groupBanner: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/group-banner.png')
    : staticPath('/images/group-banner.png'),
  group: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/group@2x.png')
    : staticPath('/images/group@2x.png'),
  honeymoon: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/honeymoon.jpg')
    : staticPath('/images/honeymoon.jpg'),
  lightbulb: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/lightbulb@2x.png')
    : staticPath('/images/lightbulb@2x.png'),
  mexico: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/mexico.jpg')
    : staticPath('/images/mexico.jpg'),
  weddingBanner: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/wedding-banner.jpg')
    : staticPath('/images/wedding-banner.jpg'),
  weddingRing: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/wedding-ring@2x.png')
    : staticPath('/images/wedding-ring@2x.png'),
  blackLeftArrow: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/black-left-arrow@2x.png')
    : staticPath('/images/black-left-arrow@2x.png'),
  blackRightArrow: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/black-right-arrow@2x.png')
    : staticPath('/images/black-right-arrow@2x.png'),
  phone: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/phone-icon@2x.png')
    : staticPath('/images/phone-icon@2x.png'),
  reset: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/reset@2x.png')
    : staticPath('/images/reset@2x.png'),
  loading: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/loading.png')
    : staticPath('/images/loading.gif'),
  alert: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/alert@2x.png')
    : staticPath('/images/alert@2x.png'),
  flame: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/flame@2x.png')
    : staticPath('/images/flame@2x.png'),
  owl: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/owl@2x.png')
    : staticPath('/images/owl@2x.png'),
  raddest: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/raddest@2x.png')
    : staticPath('/images/raddest@2x.png'),
  bedSVG: STORYBOOK ? BedSVG : staticPath('/images/bed-icon.svg'),
  checkSVG: STORYBOOK ? CheckSVG : staticPath('/images/check-icon.svg'),
  clipboardSVG: STORYBOOK ? ClipboardSVG : staticPath('/images/clipboard-icon.svg'),
  flightSVG: STORYBOOK ? FlightSVG : staticPath('/images/flights-icon.svg'),
  personSVG: STORYBOOK ? PersonSVG : staticPath('/images/person-icon.svg'),
  youtubeSVG: STORYBOOK ? YouTubeSVG : staticPath('/images/youtube-icon.svg'),
  fourOFourTitle:
    STORYBOOK || __DEBUG__ ? FourOFourTitle : staticPath('/images/four-o-four-title.svg'),
  visa: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/visa@2x.png')
    : staticPath('/images/visa@2x.png'),
  mastercard: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/mastercard@2x.png')
    : staticPath('/images/mastercard@2x.png'),
  amex: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/amex@2x.png')
    : staticPath('/images/amex@2x.png'),
  discovercard: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/discovercard@2x.png')
    : staticPath('/images/discovercard@2x.png'),
  experienceBanner: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/desktop-landing-experiences-banner.jpg')
    : staticPath('/images/desktop-landing-experiences-banner.jpg'),
  droneBanner: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/drone-banner.jpg')
    : staticPath('/images/drone-banner.jpg'),
  landingBanner: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/landing-hero.jpg')
    : staticPath('/images/landing-hero.jpg'),
  experienceHero: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/experiences-hero.jpg')
    : staticPath('/images/experiences-hero.jpg'),
  hotelListHero: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/hotel-list-hero.jpg')
    : staticPath('/images/hotel-list-hero.jpg'),
  droneHero: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/drone-hero.png')
    : staticPath('/images/drone-hero.png'),
  fourOFourMobile: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/404-hero-mobile.png')
    : staticPath('/images/404-hero-mobile.png'),
  fourOFourTablet: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/404-hero-tablet.png')
    : staticPath('/images/404-hero-tablet.png'),
  fourOFourDesktop: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/404-hero-desktop.png')
    : staticPath('/images/404-hero-desktop.png'),
  triangleGrey: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/triangle-grey.png')
    : staticPath('/images/triangle-grey@2x.png'),
  filterGrey: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/filter-vector-grey@2x.png')
    : staticPath('/images/filter-vector-grey@2x.png'),
  filterWhite: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/filter-vector-white@2x.png')
    : staticPath('/images/filter-vector-white@2x.png'),
  hotelWithPalms: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/Unavailable Icon.png')
    : staticPath('/images/Unavailable Icon.png'),
  lock: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/lock.png')
    : staticPath('/images/lock.png'),
  maximize: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/maximize.png')
    : staticPath('/images/maximize.png'),
  minimize: STORYBOOK
    ? require('../../../backend/svh_travel/static/images/minimize.png')
    : staticPath('/images/minimize.png'),
}

export default Images
