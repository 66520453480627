// @flow

export default {
  App: {
    Nav: 'Accessibility/App/Nav',
    Logo: 'Accessibility/App/Logo',
    Footer: 'Accessibility/App/Footer',
    CallUs: 'Accessibility/App/CallUs',
  },
  Screens: {
    Home: {
      ExperienceButton: 'Accessibility/Screens/Homepage/ExperienceButton',
    },
    Quiz: {
      Start: 'Accessibility/Screens/Quiz/StartButton',
      ResultHotel: 'Accessibility/Screens/Quiz/ResultHotel',
    },
    PrivacyPolicy: 'Accessibility/Screens/PrivacyPolicy',
    TermsAndConditions: 'Accessibility/Screens/TermsAndConditions',
    Checkout: {
      CheckBox: (idx: string) => `Accessibility/Screens/Checkout/CheckBox/${idx}`,
      Radio: (idx: string) => `Accessibility/Screens/Checkout/Radio/${idx}`,
      ChangeButton: 'Accessibility/Screens/Checkout/ChangeButton',
      Input: (idx: string) => `Accessibility/Screens/Checkout/Input/${idx}`,
      FlightDetail: 'Accessibility/Screens/Checkout/FlightDetail',
      Steps: 'Accessibility/Screens/Checkout/Steps',
      Back: 'Accessibility/Screens/Checkout/Back',
      Next: 'Accessibility/Screens/Checkout/Next',
      Button: {
        ChangeDate: 'Accessibility/Screens/Checkout/Button/ChangeDate',
        ChangeRoom: 'Accessibility/Screens/Checkout/Button/ChangeRoom',
      },
    },
    Itinerary: {
      Cancel: 'Accessibility/Screens/Itinerary/Cancel',
      CancelReservationCancel: 'Accessibility/Screens/Itinerary/CancelReservationCancel',
      CancelReservationConfirm: 'Accessibility/Screens/Itinerary/CancelReservationConfirm',
    },
  },
  Search: {
    From: 'Accessibility/Search/From',
    To: 'Accessibility/Search/To',
    FromDate: 'Accessibility/Search/FromDate',
    ToDate: 'Accessibility/Search/ToDate',
    toDate: 'Accessibility/Search/ToDate',
    Adult: 'Accessibility/Search/Adult',
    Children: 'Accessibility/Search/Children',
    Search: 'Accessibility/Search/Search',
    ChildAge: 'Accessibility/Search/ChildAge',
    includeFlight: 'Accessibility/Search/includeFlight',
  },
  PopularDestination: {
    Card: (destCode: string) => `Accessibility/Screens/PopularDestination/Card/${destCode}`,
  },
  Drone: {
    Hero: 'Accessibility/Drone/Hero',
    Watch: 'Accessibility/Drone/Watch',
  },
  Hero: {
    Baggage: 'Accessibility/Hero/Baggage',
    Drone: 'Accessibility/Hero/Drone',
    FlightResults: 'Accessibility/Hero/FlightResults',
    ItineraryDetail: 'Accessibility/Hero/ItineraryDetail',
    Main: 'Accessibility/Hero/Main',
    HotelResults: 'Accessibility/Hero/HotelResults',
  },
  Modal: {
    FlightStop: 'Accessibility/Modal/FlightStop',
    CancelReservation: 'Accessibility/Modal/CancelReservation',
  },
  Components: {
    BookHotel: 'Accessibility/Components/Modal/BookHotel',
    FilterSidebar: {
      FilterCheckbox: 'Accessibility/Components/FilterSidebar/FilterCheckbox',
    },
    FlightResults: {
      ChooseFlightButton: 'Accessibility/Components/FlightResults/ChooseFlightButton',
    },
    HotelListItem: 'Accessibility/Components/Modal/HotelListItem',
    HotelRoom: 'Accessibility/Components/Modal/HotelRoom',
    Modal: {
      Filter: 'Accessibility/Components/Modal/Filter',
      Button: 'Accessibility/Components/Modal/Button',
    },
    SearchBar: {
      UpdateSearchButton: 'Accessibility/Components/SearchBar/UpdateSearchButton',
    },
    InfoCard: 'Accessibility/Components/InfoCard',
    Sidebar: 'Accessibility/Components/Sidebar',
    HotelInfo: {
      Banner: 'Accessibility/Components/HotelInfo/Banner',
      Card: 'Accessibility/Components/HotelInfo/Card',
    },
    ProgressBar: 'Accessibility/Components/ProgressBar',
    Loading: 'Accessibility/Components/Loading',
  },
}
