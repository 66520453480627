// @flow

import { BaseModel } from '.'
import { defaultString, defaultObject } from './defaults'

type AddressDataType = {
  line_1: string,
  line_2: ?string,
  city: string,
  state_province_code: string,
  state_province_name: string,
  postal_code: string,
  country_code: string,
  obfuscation_required: boolean,
}

type LocationDataType = {
  coordinates: {
    latitude: number,
    longitude: number,
  },
}

type InformationDataType = {
  [string]: { id: string, name: string, value: ?string | ?number, categories: ?Array<string> },
}

export type ImageDataType = {
  hero_image: boolean,
  category: number,
  caption: string,
  links: {
    '70px': ?{ method: string, href: string, expires: ?string },
    '200px': ?{ method: string, href: string, expires: ?string },
    '350px': ?{ method: string, href: string, expires: ?string },
    '1000px': ?{ method: string, href: string, expires: ?string },
  },
}

type OnsitePaymentDataType = {
  currency: string,
  types: { [string]: { id: string, name: string } },
}

type BedGroupDataType = {
  id: string,
  description: string,
  configuration: Array<{ type: string, size: string, quantity: number }>,
  links: { price_check: { method: string, href: string, expires: ?string } },
}

export type PriceDataType = {
  type: string,
  value: string,
  currency: string,
}

export type ChargeDataType = {
  billable_currancy: PriceDataType,
  request_currency: PriceDataType,
}

export type PricingDataType = {
  nightly: Array<PriceDataType>,
  stay: Array<PriceDataType>,
  totals: {
    inclusive: ChargeDataType,
    exclusive: ChargeDataType,
    strikethrough: ChargeDataType,
    marketing_fee: ChargeDataType,
    gross_profit: ChargeDataType,
    minimum_selling_price: ChargeDataType,
    property_fees: ChargeDataType,
  },
  fees: {
    mandatory_fee: ChargeDataType,
    resort_fee: ChargeDataType,
    mandatory_tax: ChargeDataType,
  },
}

export type RateDataType = {
  id: string,
  status: string,
  available_rooms: number,
  refundable: boolean,
  sale_scenario: {
    package: boolean,
    member: boolean,
    corporate: boolean,
    distribution: boolean,
  },
  merchant_of_record: string,
  amenities: InformationDataType,
  links: { [string]: { method: string, href: string, expires: ?string } },
  bed_groups: { [string]: BedGroupDataType },
  cancel_penalties: Array<{
    currency: string,
    start: string,
    end: string,
    amount: string,
    nights: string,
    percent: string,
  }>,
  occupancy_pricing: {
    [string]: PricingDataType,
  },
}

export type RoomDataType = {
  id: string,
  name: string,
  descriptions: { overview: string },
  amenities: InformationDataType,
  images: Array<ImageDataType>,
  bed_groups: { [string]: BedGroupDataType },
  area: { square_meters: Number, square_feet: number },
  views: { [string]: { id: string, name: string } },
  occupancy: {
    max_allowed: { total: number, children: number, adults: number },
    age_categories: { [string]: { name: string, minimum_age: number } },
  },
  rates: Array<RateDataType>,
}

export type PriceCheckDataType = {
  id: string,
  status: string,
  links: { [string]: { method: string, href: string, expires: ?string } },
  occupancy_pricing: {
    [string]: {
      nightly: Array<PriceDataType>,
      stay: Array<PriceDataType>,
      totals: {
        inclusive: ChargeDataType,
        exclusive: ChargeDataType,
        strikethrough: ChargeDataType,
        marketing_fee: ChargeDataType,
        gross_profit: ChargeDataType,
        minimum_selling_price: ChargeDataType,
        property_fees: ChargeDataType,
      },
      fees: {
        mandatory_fee: ChargeDataType,
        resort_fee: ChargeDataType,
        mandatory_tax: ChargeDataType,
      },
    },
  },
  card_on_file_limit: { value: string, currency: string },
  refundable_damage_deposit: { value: string, currency: string },
  deposits: Array<{
    value: string,
    due: string,
    currency: string,
  }>,
}

export type PropertyMetadataDataType = {
  code: string,
  name: string,
  address: AddressDataType,
  ratings: {
    property: {
      rating: string,
      type: string,
    },
    guest: {
      count: number,
      overall: string,
      cleanliness: string,
      service: string,
      comfort: string,
      condition: string,
      location: string,
      neighborhood: string,
      quality: string,
      value: string,
      amenities: string,
      recommendation_percent: string,
    },
  },
  location: LocationDataType,
  phone: string,
  fax: string,
  category: {
    id: string,
    name: string,
  },
  rank: number,
  business_model: {
    expedia_collect: boolean,
    property_collect: boolean,
  },
  checkin: {
    '24_hour': string,
    begin_time: string,
    end_time: string,
    instructions: string,
    special_instructions: string,
    min_age: number,
  },
  checkout: {
    time: string,
  },
  fees: {
    mandatory: string,
    optional: string,
  },
  policies: {
    know_before_you_go: string,
  },
  attributes: {
    general: InformationDataType,
    pets: InformationDataType,
  },
  amenities: InformationDataType,
  images: Array<ImageDataType>,
  onsite_payments: OnsitePaymentDataType,
  rooms: Array<RoomDataType>,
  descriptions: {
    amenities: string,
    dining: string,
    renovations: string,
    national_ratings: string,
    business_amenities: string,
    rooms: string,
    attractions: string,
    location: string,
    headline: string,
    general: string,
  },
  statistics: InformationDataType,
  all_inclusive: {
    all_rate_plans: boolean,
    some_rate_plans: boolean,
    details: string,
  },
}

export default class Property extends BaseModel {
  code: ?string

  region: ?string

  metadata: PropertyMetadataDataType

  dining_and_entertainment: ?string

  constructor(
    data?: {
      code: ?string,
      region: ?string,
      dining_and_entertainment: ?string,
      metadata: PropertyMetadataDataType,
    } = {
      code: null,
      region: null,
      dining_and_entertainment: null,
      metadata: {},
    },
  ) {
    super()
    this.code = defaultString(data.code)
    this.region = defaultString(data.region)
    this.metadata = defaultObject(data.metadata)
    this.dining_and_entertainment = defaultString(data.dining_and_entertainment)
  }
}
