// @flow

import axios from 'axios'
import type { $AxiosXHR } from 'axios'
import { Regex, DynamicPackageIds } from '@/constants'
import { modelMapper, ModelTypes } from '@/models'
import config from '../config'

export function pathJoin(paths: Array<string>): string {
  const separator = '/'
  const replace = new RegExp(`[${separator}]{2,}`, 'g')
  paths.push(separator)
  return paths.join(separator).replace(replace, separator)
}

const ENDPOINT = {
  getLocations: 'expedia/locations/',
  searchHotels: 'expedia/check-availability/',
  priceCheck: 'expedia/price-check/',
  createBooking: 'expedia/create-booking/',
  checkItinerary: 'expedia/itinerary-detail/',
  cancelBooking: 'expedia/itinerary-cancel/',
  payBooking: 'authorize-net/pay-booking/'
}

export class ApiService extends Object {
  axios: axios.Axios

  sessionId: ?string

  sessionTimestamp: ?number

  dynamicPackageId: $Values<typeof DynamicPackageIds>

  constructor() {
    super()
    this.axios = axios.create({
      baseURL: config.apiURL,
      timeout: 200000,
      headers: { 'Content-Type': 'application/json' },
      data: {},
    })
    this.sessionId = null
    this.sessionTimestamp = null
    this.axios.interceptors.response.use(
      response => {
        // $FlowFixMe
        const { url, apiResponseType } = response.config
        const endpointMatch = url.match(Regex.finalPath)
        // $FlowFixMe
        if (!response.data?.task) {
          const endpoint = endpointMatch ? endpointMatch[0] : ''
          const key = apiResponseType || endpoint
          // $FlowFixMe
          response.data = modelMapper(key, response.data)
        }
        return response
      },
      error => Promise.reject(error)
    )
  }

  // API REQUESTS
  getLocations = async (): Promise<$AxiosXHR<Object>> => this.axios.get(
    ENDPOINT.getLocations,
    { apiResponseType: ModelTypes.Location }
  )

  searchProperties = async (data: SearchPropertiesRequest): Promise<$AxiosXHR<Object>> => this.axios.post(
    ENDPOINT.searchHotels,
    data,
    { apiResponseType: ModelTypes.Property }
  )

  priceCheck = async (data: CheckPriceRequest): Promise<$AxiosXHR<Object>> => this.axios.get(
    ENDPOINT.priceCheck,
    // $FlowFixMe
    { params: data }
  )

  createBooking = async (data: CreateBookingRequest): Promise<$AxiosXHR<Object>> => this.axios.post(
    ENDPOINT.createBooking,
    data
  )

  checkItinerary = async (data: {itinerary_id: string}): Promise<$AxiosXHR<Object>> => this.axios.get(
    ENDPOINT.checkItinerary,
    // $FlowFixMe
    { params: data }
  )

  payBooking = async (data: PayBookingRequest): Promise<$AxiosXHR<Object>> => this.axios.post(
    ENDPOINT.payBooking,
    data
  )

  cancelBooking = async (data: CancelBookingRequest): Promise<$AxiosXHR<Object>> => this.axios.post(
    ENDPOINT.cancelBooking,
    data
  )
}

export default new ApiService()
