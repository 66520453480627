// @flow
import Strings from './strings'
import { ReplaceErrors, DisplayErrors } from './errors'
import Images, { staticPath } from './images'
import countryCodesJSON from '../../../backend/soap/country_codes.json'

type NavLinksObj = { path: string, name: string, blank: boolean }
type TimestampObj = { six: number, seven: number, eight: number }

const PhoneNumber = '+18886675929'

//
export const SupportedCountryCodes = countryCodesJSON.country_codes
// Routes
export const FEATURE = 'feature'
export const PAYMENT = 'payment'

export const DEFAULT_NUMBER_NIGHTS = 3
export const MIN_NUMBER_NIGHTS = 2
export const MAX_NUMBER_NIGHTS = 28
export const MERCHANT_FEE = 1.05

const Routes = {
  // Generic
  Home: '/',
  Baggage: '/baggage',
  Experiences: '/experiences',
  Review: '/review-itinerary/:itineraryId',
  Drones: '/drone-videos',
  SearchResults: '/search-results',
  CookiePolicy: '/cookie-policy',
  PrivacyPolicy: '/privacy-policy',
  FourOFour: '*',
  TermsAndConditions: '/terms-and-conditions',
  Checkout: '/checkout',
  FeatureCheckout: `/checkout/:sessionId/${FEATURE}`,
  PaymentCheckout: `/checkout/:sessionId/${PAYMENT}`,
  HotelInfo: '/hotel/:code',
  ContactUs: `tel:${PhoneNumber}`,
  PopularDestination: `/popular-destination/:destCode`,
  wixHome: 'https://www.allinclusivevacations.com',
}

const { Review } = Routes

const NavLinks: Array<NavLinksObj> = [
  { path: 'https://www.allinclusivevacations.com', name: 'Home', blank: false },
  { path: 'https://www.allinclusivevacations.com/about-us', name: 'About', blank: false },
  {
    path: 'https://www.allinclusivevacations.com/destinations',
    name: 'Destinations',
    blank: false,
  },
  {
    path: 'https://www.allinclusivevacations.com/drone-videos',
    name: 'Drone Videos',
    blank: false,
  },
  { path: Review.replace(':itineraryId', 'search'), name: 'Review Itinerary', blank: false },
]

export const FooterLinks: Array<NavLinksObj> = [
  { path: Routes.PrivacyPolicy, name: 'Privacy Policy', blank: false },
  { path: Routes.TermsAndConditions, name: 'Terms of Use', blank: false },
]

export const FooterQuickLinks: Array<NavLinksObj> = [
  { path: 'https://www.allinclusivevacations.com/about-us', name: 'About Us', blank: false },
  {
    path: 'https://www.allinclusivevacations.com/destinations',
    name: 'Destinations',
    blank: false,
  },
  { path: Review.replace(':itineraryId', 'search'), name: 'Review Itinerary', blank: false },
]

export const SocialLinks = {
  facebook: 'https://www.facebook.com/InclusiveVacation/',
  instagram: 'https://www.instagram.com/allinclusivevacation/',
  youtube: 'https://www.youtube.com/user/InclusiveVacation/',
}

export const HOTEL_TYPES = [
  ' - All Inclusive',
  '- Unlimited-Luxury®',
  ' - European Plan',
  ' - Luxury®',
  ' Adults Only',
  '-EUR PLAN',
]

export const TIMESTAMP_HOURS: TimestampObj = {
  six: 21600000,
  seven: 25200000,
  eight: 28800000,
}

const Regex = {
  finalPath: /[^/]+(?=.[^/]*$)/,
  stripHtmlTags: /<\/?[^>]+>/gi,
  // eslint-disable-next-line max-len
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape
  formattedDate: /^[0-9]{2}\s[/]\s[0-9]{2}\s[/]\s[0-9]{4}$/,
  errorDP: /^(DP:\s)/,
}

const AltText = {
  facebook: 'Facebook Icon',
  youtube: 'Youtube Icon',
  instagram: 'Instagram Icon',
  footerLogo: 'Footer Logo',
  navbar: 'Navbar',
  hotelActive: 'Hotel Active Icon',
  hotelInactive: 'Hotel Inactive Icon',
  flightActive: 'Flight Active Icon',
  flightInactive: 'Flight Inactive Icon',
  experienceIcon: 'Experience Icon',
  fullStar: 'Full Star Icon',
  emptyStar: 'Empty Star Icon',
}

// Backend Values
const PayeeCodes = {
  passengerOnly: 'PA',
  payeeOnly: 'PE',
  passengerAndPayee: 'PP',
}

export const StatusCodes = {
  noChange: 'NN',
  priceChange: 'NP',
  availabilityChange: 'NA',
  errorStatus: 'NA',
  cancelled: 'XX',
  confirmed: 'HK',
  missingFeature: 'MF',
  pendingCancel: 'XI',
}

const CreditCardNames = {
  visa: 'Visa',
  masterCard: 'MasterCard',
  discover: 'Discover',
  americanExpress: 'American Express',
}

export type CreditCardNames$Keys = $Values<typeof CreditCardNames>

export const CreditCardCodes = [
  { label: 'Visa', value: 'VI' },
  { label: 'Mastercard', value: 'MC' },
  { label: 'Discover', value: 'DI' },
  { label: 'American Express', value: 'AX' },
  { label: 'Agency Check', value: 'CK' },
  { label: 'Diners Club Card', value: 'DC' },
  { label: 'JCB Card', value: 'JC' },
  { label: 'China Union Pay Card', value: 'CP' },
  { label: 'ME Card ', value: 'ME' },
  { label: 'Sears Card', value: 'SC' },
  { label: 'United Travel Card', value: 'UT' },
  { label: 'Customer Credit', value: 'DF' },
]

export const DestTypeEnum = {
  depart: 'Depart',
  arrive: 'Arrive',
  checkin: 'Checkin',
}

export const BookingStatusEnum = {
  depart: 'Depart',
  arrive: 'Arrive',
  checkin: 'Checkin',
}

export const DynamicPackageIds = {
  AirAndHotel: 'AH01',
  Hotel: 'H01',
  Air: 'A01',
}
// Key is the word to search in the hotel name
// Value is the tag text
export const HotelTagKeyWords = {
  Adult: Strings.adultsOnly,
  Family: Strings.familyFriendly,
  Inclusive: Strings.general.allInclusive,
}

export const FeatureSubType = {
  B: 'Sightseeing & Tours',
  D: 'Additional Options',
  E: 'Special Events',
  G: 'Lei Greetings',
  I: 'Travel Protection',
  L: 'Winter Activities',
  M: 'Meals/Dining',
  N: 'Additional Options',
  P: 'Penalty Fees for Cancellations and Revisions',
  R: 'Ground Transportation',
  S: 'Shows',
  T: 'Theme Parks',
  U: 'Internal Use Only',
  V: 'Weddings',
  W: 'Walt Disney World',
  X: 'Ground Transportation',
  Z: 'Additional Options',
}

export const FeatureNumbers = {
  VacationSecurity: 'FAPV4VC',
  CashBackVacationSecurity: 'FAPV4CB',
}

export const CHD = 'CHD'

export const SNR = 'SNR'

export const ADT = 'ADT'

export const TravelerTypes = {
  [ADT]: 'Adult',
  [SNR]: 'Senior',
  [CHD]: 'Child',
}

export const Genders = {
  Male: 'M',
  Female: 'F',
}

export const TrueFalse = {
  Yes: 'true',
  No: 'false',
}

export const DISPLAY_DATE_FORMAT = 'MM / DD / YYYY'

export const Airlines = {
  '3M': 'Silver Airways',
  '4O': 'Interjet',
  AA: 'American Airlines',
  AC: 'Air Canada',
  AM: 'Aeromexico',
  AS: 'Alaska Airlines',
  AV: 'Avianca Airlines',
  B6: 'JetBlue',
  BB: 'Seaborne',
  BW: 'Caribbean Airlines',
  CM: 'Copa Airlines',
  DE: 'Condor',
  DL: 'Delta Air Lines',
  DY: 'Norwegian Air Shuttle',
  EA: 'Eastern Airlines',
  F9: 'Frontier Airlines',
  FI: 'Icelandair',
  G4: 'Allegiant Air',
  HA: 'Hawaiian Airlines',
  HR: 'Hahn Air Lines',
  JJ: 'Tam Airlines',
  KL: 'KLM Royal Dutch Airlines',
  KX: 'Cayman Airways',
  LA: 'Lan Airlines',
  LI: 'Liat',
  LL: 'Miami Air',
  MW: 'Mokulele Airline',
  NK: 'Spirit Airlines',
  SY: 'Sun Country Airlines',
  TA: 'Taca Air',
  TS: 'Air Transat',
  TX: 'Air Caraibes',
  UA: 'United Airlines',
  UP: 'Bahamas Air',
  VB: 'Viva Aerobus',
  WG: 'Sunwing Airlines',
  WM: 'Windward Island Airways',
  WN: 'Southwest Airlines',
  WQ: 'Swift Air',
  WS: 'WestJet',
  XP: 'Xtra Airways',
  Y4: 'Volaris',
}

export const AlignmentTypes = {
  center: 'center',
  left: 'left',
  right: 'right',
}

export {
  Routes,
  Images,
  Regex,
  NavLinks,
  AltText,
  PayeeCodes,
  CreditCardNames,
  ReplaceErrors,
  DisplayErrors,
  staticPath,
}

export default Strings
