// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.scss'

type AnchorProps = {|
  ariaLabel: string,
  label: string,
  path: string,
  blank?: boolean,
|}

const Anchor = function(props: AnchorProps) {
  const { ariaLabel, label, path, blank } = props

  return (
    <Link
      aria-label={ariaLabel}
      className={styles.anchor}
      to={{ pathname: path }}
      target={blank ? '_blank' : '_self'}
    >
      {label}
    </Link>
  )
}

export default Anchor

Anchor.defaultProps = {
  blank: false
}