// @flow
import { defaultString } from './defaults'

export type BaseType = {
  id?: ?string,
}

export default class Model {
  id: string

  constructor(data?: BaseType = { id: null }) {
    this.id = defaultString(data.id)
  }
}
