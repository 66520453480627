// @flow

import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import { ACTIONS as API_ACTIONS } from '@/redux/api'
import { ACTIONS as APPLE_ACTIONS } from '@/redux/apple'
import * as api from './api'
import * as apple from './apple'
import * as startUp from './start-up'

export { api, apple }

export default function* root(): GeneratorType {
  yield all([
    // GENERAL
    takeEvery(API_ACTIONS.MAKE_EVERY_REQUEST, api.makeRequest),

    // PROPERTIES
    takeEvery(APPLE_ACTIONS.SEARCH_PROPERTIES_REQUEST, apple.searchProperties),
    takeEvery(APPLE_ACTIONS.CHECK_PRICE_REQUEST, apple.checkPrice),
    takeEvery(APPLE_ACTIONS.CREATE_BOOKING_REQUEST, apple.createBooking),
    takeEvery(APPLE_ACTIONS.CHECK_ITINERARY_REQUEST, apple.checkItinerary),
    takeEvery(APPLE_ACTIONS.CANCEL_BOOKING_REQUEST, apple.cancelBooking),
    takeEvery(APPLE_ACTIONS.CANCEL_BOOKING_SUCCESS, apple.cancelBookingSuccess),
    takeEvery(APPLE_ACTIONS.PAY_BOOKING_REQUEST, apple.payBooking),
  ])
  yield all([takeLatest(API_ACTIONS.MAKE_LATEST_REQUEST, api.makeRequest)])
  yield startUp.startUp()
}
