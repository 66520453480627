// @flow
import moment from 'moment'
import ApiService from '@/helpers/ApiService'
import { DISPLAY_DATE_FORMAT } from '@/constants'
import { camelCaseToSnakeCase } from '@/helpers'
import { reduxSet as appleReduxSet } from './apple'

const keyObj = obj =>
  Object.keys(obj).reduce((acc, curr) => {
    acc[curr] = curr
    return acc
  }, {})

const endpoints = keyObj(ApiService)

const appleReducers = keyObj(appleReduxSet)

const storeKeys = {
  apple: 'apple',
  api: 'api',
}

export const searchPropertiesRequest = (data: SearchPayload): ApiPayloadType<SearchPropertiesRequest> => {
  const { fromDate, toDate, destination, code, adults, children } = data
  const payloadData = {
    property_id: code,
    destination,
    checkin_date: moment(fromDate, DISPLAY_DATE_FORMAT).format('YYYY-MM-DD'),
    checkout_date: moment(toDate, DISPLAY_DATE_FORMAT).format('YYYY-MM-DD'),
    children,
    adults
  }

  return {
    url: endpoints.searchProperties,
    reducerKey: appleReducers.searchPropertiesSuccess,
    storeName: storeKeys.apple,
    // $FlowFixMe
    data: payloadData,
  }
}

export const getLocationsRequest = (): ApiPayloadType<null> => ({
  url: endpoints.getLocations,
  reducerKey: appleReducers.getLocationsSuccess,
  storeName: storeKeys.apple,
  data: null,
})

export const checkPriceRequest = (data: CheckPricePayload): ApiPayloadType<CheckPriceRequest> => ({
  url: endpoints.priceCheck,
  reducerKey: appleReducers.checkPriceSuccess,
  storeName: storeKeys.apple,
  data
})

export const createBookingRequest = (data: CreateBookingPayload): ApiPayloadType<CreateBookingRequest> => ({
  url: endpoints.createBooking,
  reducerKey: appleReducers.createBookingSuccess,
  storeName: storeKeys.apple,
  // $FlowFixMe
  data: camelCaseToSnakeCase(data)
})

export const payBookingRequest = (data: PayBookingRequest): ApiPayloadType<PayBookingRequest> => ({
  url: endpoints.payBooking,
  reducerKey: appleReducers.payBookingSuccess,
  storeName: storeKeys.apple,
  // $FlowFixMe
  data: camelCaseToSnakeCase(data)
})

export const checkItineraryRequest = (data: {itineraryId: string}): ApiPayloadType<{itinerary_id: string}> => ({
  url: endpoints.checkItinerary,
  reducerKey: appleReducers.checkItinerarySuccess,
  storeName: storeKeys.apple,
  // $FlowFixMe
  data: camelCaseToSnakeCase(data)
})

export const cancelBookingRequest = (data: {itineraryId: string}): ApiPayloadType<CancelBookingRequest> => ({
  url: endpoints.cancelBooking,
  reducerKey: appleReducers.cancelBookingSuccess,
  storeName: storeKeys.apple,
  // $FlowFixMe
  data: camelCaseToSnakeCase(data)
})
