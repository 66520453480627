// @flow
import { jsKey } from '@/helpers'

export const defaultString = (value: ?string) =>
  typeof value === 'string' || value instanceof String ? value : ''

export const defaultNumber = (value: ?string | number) => {
  const parsed = parseInt(value, 10)
  if (Number.isNaN(parsed)) {
    return 0
  }
  return parsed
}

export const defaultFloat = (value: ?string | number) => {
  if (typeof value === 'string' || value instanceof String) {
    return parseFloat(value)
  }
  if (!value || typeof value !== 'number') {
    return 0
  }
  return value
}

export const defaultBoolean = (value: ?boolean): boolean => !!value

export const defaultArray = (value: ?any): Array<any> => {
  let list = []
  if (Array.isArray(value)) {
    list = value
  } else if (value) {
    list = [value]
  }
  return list
}

// $FlowFixMe
export const defaultPrice = (value: ?number): number => (isNaN(value) ? 0.0 : value) // eslint-disable-line no-restricted-globals

export function defaultEnum<EnumType, EnumObject>(
  value: ?EnumType,
  enumObj: EnumObject,
): EnumType | '' {
  // $FlowFixMe
  return Object.values(enumObj).indexOf(value) > -1 && value !== null && value !== undefined
    ? value
    : ''
}

export function defaultObject(value: ?Object) {
  // $FlowFixMe
  const obj = {}
  if (value) {
    Object.keys(value).forEach(key => {
      //  $FlowFixMe
      const currentValue = value[key]
      obj[jsKey(key)] = currentValue
      if (Array.isArray(currentValue)) {
        obj[jsKey(key)] = currentValue.map(x => defaultObject(x))
      } else if (typeof currentValue === 'object') {
        obj[jsKey(key)] = defaultObject(currentValue)
      } else if (currentValue === 'true' || currentValue === 'false') {
        obj[jsKey(key)] = JSON.parse(currentValue)
      }
    })
  }
  return obj
}

export const defaultYesNo = (value: string) => value === 'Y'
