// @flow

import { BaseModel } from '.'
import { defaultNumber, defaultString } from './defaults'

type LocationDataType = {
  code: ?string,
  city: ?string,
  country: ?string,
  properties: ?number
}

export default class Location extends BaseModel {
  code: string

  city: string

  country: string

  properties: number

  constructor(
    data?: LocationDataType = { code: null, city: null, country: null, properties: 0 },
  ) {
    super()
    this.code = defaultString(data.code)
    this.city = defaultString(data.city)
    this.country = defaultString(data.country)
    this.properties = defaultNumber(data.properties)
  }
}
