// @flow
import _ from 'lodash'
import { DynamicPackageIds } from '@/constants'
import AppConfig from '../config'

type SendParamsType = {
  event_category?: string,
  event_label?: ?string,
  value?: number,
  page_path?: string,
  page_title?: string,
  currency?: string,
  send_to?: string,
}

const createEvent = {
  callUs: () => ({ category: 'Contact', label: null, action: 'Call' }),
  book: (category: string, label: string) => ({ category, label, action: 'Book' }),
  confirm: (category: string, label: string) => ({ category, label, action: 'Confirm' }),
  cancel: (category: string, label: string) => ({ category, label, action: 'Cancel' }),
  error: (category: string, label: string) => ({ category, label, action: 'Error' }),
  flightList: (category: string, label: string) => ({ category, label, action: 'ViewHotelList' }),
  hotelList: (category: string, label: string) => ({ category, label, action: 'ViewHotelList' }),
  hotelDetail: (category: string, label: string) => ({
    category,
    label,
    action: 'ViewHotelDetail',
  }),
}

export const tracker = {
  measurementId: AppConfig.googleAnalytics.measurementId,
  event(event: GAEventConfig, value?: number) {
    const { category, label, action } = event
    const config = {
      event_category: category,
      event_label: label,
      value,
    }
    this.send('event', action, config)
  },
  initialize() {
    this.send('config', this.measurementId)
  },
  send(...args: [string, string, SendParamsType]) {
    if (this.measurementId && window.gtag) {
      window.gtag(...args)
    }
  },
  pageView(pathname: string) {
    this.send('config', this.measurementId, {
      page_path: pathname,
    })
  },
}

export const getPackageKey = (dynamicPackageId: $Values<typeof DynamicPackageIds>) => {
  const entry = _.find(Object.entries(DynamicPackageIds), v => v[1] === dynamicPackageId)
  const key = entry ? entry[0] : ''
  return key
}

export const events = {
  clickCallUs: () => tracker.event(createEvent.callUs()),
  book: (dynamicPackageId: string, label: string, value: number) => {
    const category = getPackageKey(dynamicPackageId)
    tracker.event(createEvent.book(category, label), value)
  },
  hotelList: (dynamicPackageId: $Values<typeof DynamicPackageIds>, label: string) => {
    const category = getPackageKey(dynamicPackageId)
    tracker.event(createEvent.hotelList(category, label))
  },
  hotelDetail: (dynamicPackageId: $Values<typeof DynamicPackageIds>, label: string) => {
    const category = getPackageKey(dynamicPackageId)
    tracker.event(createEvent.hotelDetail(category, label))
  },
  flightList: (dynamicPackageId: $Values<typeof DynamicPackageIds>, label: string) => {
    const category = getPackageKey(dynamicPackageId)
    tracker.event(createEvent.hotelList(category, label))
  },
  confirm: (dynamicPackageId: $Values<typeof DynamicPackageIds>, label: string) => {
    const category = getPackageKey(dynamicPackageId)
    tracker.event(createEvent.confirm(category, label))
  },
  cancel: (dynamicPackageId: $Values<typeof DynamicPackageIds>, label: string) => {
    const category = getPackageKey(dynamicPackageId)
    tracker.event(createEvent.cancel(category, label))
  },
  error: (error: string, label: string) => {
    tracker.event(createEvent.error(error, label))
  },
  pageView: (pathname: string) => {
    tracker.pageView(pathname)
  },
  conversion: (value: number) => {
    tracker.send('event', 'conversion', {
      send_to: 'AW-967861053/SeJ6CIrJsKUBEL3Gwc0D',
      value,
      currency: 'USD'
    })
  }
}

export default events
