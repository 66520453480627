// @flow
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { Suspense, lazy } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import '@babel/polyfill'

import configureStore from '@/redux'
import { Navbar, ScrollToTop, Loading } from '@/components'
import { Routes } from '@/constants'
import history from '@/helpers/history'
import fbPixel from '@/helpers/fb-pixel'
import App from '@/screens'
import events from '@/helpers/ga'
import styles from '../styles/main.scss'
// $FlowFixMe
import config from './config'

const Homepage = lazy(() => import('@/screens/home'))
const FourOFour = lazy(() => import('@/screens/four-o-four'))
const HotelResults = lazy(() => import('@/screens/hotel-results'))
const Itinerary = lazy(() => import('@/screens/itinerary'))
const PrivacyPolicy = lazy(() => import('@/screens/privacy-policy'))
const CookiePolicy = lazy(() => import('@/screens/cookie-policy'))
const TermsAndConditions = lazy(() => import('@/screens/terms-and-conditions'))
const HotelInfo = lazy(() => import('@/screens/hotel-info'))
const Checkout = lazy(() => import('@/screens/checkout'))
const PopularDestination = lazy(() => import('@/screens/popular-destination'))

// Normalize must load first or it will overwrite our styles
require('normalize.css/normalize.css')
require('../styles/fonts.scss')
require('../styles/defaults.scss')

fbPixel.init()
history.listen(location => {
  events.pageView(location.pathname)
  fbPixel.pageView()
})

const promise = loadStripe(config.stripePubKey)

const routes = (
  <Provider store={configureStore()}>
    <ConnectedRouter history={history}>
      <Elements stripe={promise}>
        <Suspense
          fallback={
            <div>
              <Navbar />
              <div className={styles.mainSpinner}>
                <Loading />
              </div>
            </div>
          }
        >
          <ScrollToTop>
            <App>
              <Switch>
                <Route exact path={Routes.Home} component={Homepage} />
                <Route exact path={Routes.SearchResults} component={HotelResults} />
                <Route exact path={Routes.HotelInfo} component={HotelInfo} />
                <Route exact path={Routes.Review} component={Itinerary} />
                <Route exact path={Routes.CookiePolicy} component={CookiePolicy} />
                <Route exact path={Routes.PrivacyPolicy} component={PrivacyPolicy} />
                <Route exact path={Routes.TermsAndConditions} component={TermsAndConditions} />
                <Route exact path={Routes.PopularDestination} component={PopularDestination} />
                <Route path={Routes.Checkout} component={Checkout} />
                <Route path={Routes.FourOFour} component={FourOFour} />
              </Switch>
            </App>
          </ScrollToTop>
        </Suspense>
      </Elements>
    </ConnectedRouter>
  </Provider>
)

const app = document.getElementById('app')
if (app) render(routes, app)

export default routes
