// @flow

import { compose, createStore, combineReducers, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import history from '@/helpers/history'
import rootSaga from '@/sagas'
import { reducer as apple, reduxSet as appleAC, INITIAL_STATE as AppleInitialState } from './apple'
import { reducer as api, reduxSet as apiAC, INITIAL_STATE as APIInitialState } from './api'

// Strange higher-order function to potentially modify the result
const logAction = store => next => action => {
  const before = store.getState()
  const result = next(action)
  if (process.env.NODE_ENV !== 'production') {
    // Group these console logs into one closed group
    /* eslint-disable no-console */
    const after = store.getState()
    console.groupCollapsed(`dispatching action => ${action.type}`)
    console.log('BEFORE', before)
    console.log('ACTION', action.type, action)
    console.log('AFTER', after)
    console.groupEnd()
    /* eslint-enable no-console */
  }

  return result
}

const reducers = combineReducers({
  apple,
  api,
  router: connectRouter(history),
})

export const InitialState = {
  apple: AppleInitialState,
  api: APIInitialState,
}

const ActionCreators = {
  apple: appleAC,
  api: apiAC,
}

export { ActionCreators }

const configureStore = (initialState: StoreState = InitialState) => {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware, logAction, routerMiddleware(history)]

  let middleware = applyMiddleware(...middlewares)

  if (process.env.NODE_ENV !== 'production') {
    const { devToolsExtension } = window
    if (typeof devToolsExtension === 'function') {
      middleware = compose(
        middleware,
        devToolsExtension(),
      )
    }
  }
  // $FlowFixMe
  const store = createStore(reducers, initialState, middleware)
  sagaMiddleware.run(rootSaga)

  return store
}

export default configureStore
