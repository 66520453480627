// @flow

import { dispatchReducer, runReducers } from '@/helpers/dispatch-and-reduce'
import { startRequest, endRequest, noOp } from './shared-reducers'
import { Property } from '../models'
import type { PriceCheckDataType } from '../models/property'

export const ACTIONS = {
  START_REQUEST: 'APPLE/START_REQUEST',
  END_REQUEST: 'APPLE/END_REQUEST',
  GET_LOCATIONS_SUCCESS: 'EXPEDIA/GET_LOCATIONS_SUCCESS',
  SEARCH_PROPERTIES_REQUEST: 'EXPEDIA/SEARCH_PROPERTIES_REQUEST',
  SEARCH_PROPERTIES_SUCCESS: 'EXPEDIA/SEARCH_PROPERTIES_SUCCESS',
  CLEAR_PROPERTY_ERROR: 'EXPEDIA/CLEAR_PROPERTY_ERROR',
  CLEAR_LOCATION_ERROR: 'EXPEDIA/CLEAR_LOCATION_ERROR',
  CHECK_PRICE_REQUEST: 'EXPEDIA/CHECK_PRICE_REQUEST',
  CHECK_PRICE_SUCCESS: 'EXPEDIA/CHECK_PRICE_SUCCESS',
  CREATE_BOOKING_REQUEST: 'EXPEDIA/CREATE_BOOKING_REQUEST',
  CREATE_BOOKING_SUCCESS: 'EXPEDIA/CREATE_BOOKING_SUCCESS',
  CHECK_ITINERARY_REQUEST: 'EXPEDIA/CHECK_ITINERARY_REQUEST',
  CHECK_ITINERARY_SUCCESS: 'EXPEDIA/CHECK_ITINERARY_SUCCESS',
  CANCEL_BOOKING_REQUEST: 'EXPEDIA/CANCEL_BOOKING_REQUEST',
  CANCEL_BOOKING_SUCCESS: 'EXPEDIA/CANCEL_BOOKING_SUCCESS',
  FILTER_PROPERTY: 'EXPEDIA/FILTER_PROPERTY',
  PAY_BOOKING_REQUEST: 'AUTHORIZE_NET/PAY_BOOKING_REQUEST',
  PAY_BOOKING_SUCCESS: 'AUTHORIZE_NET/PAY_BOOKING_SUCCESS',
}

export const INITIAL_STATE: AppleStoreState = {
  // LOCATIONS
  locations: [],
  getLocationsPending: false,
  getLocationsError: null,

  // PROPERTIES
  properties: [],
  searchPropertiesPending: false,
  searchPropertiesError: null,

  // PRICE CHECK
  priceCheck: {},
  priceCheckPending: false,
  priceCheckError: null,

  // CREATE BOOKING
  createBooking: { status: null },
  createBookingPending: false,
  createBookingError: null,

  // PAY BOOKING
  payBooking: {},
  payBookingPending: false,
  payBookingError: null,

  // CHECK ITINERARY
  checkItinerary: {},
  checkItineraryPending: false,
  checkItineraryError: null,

  // CANCEL BOOKING
  cancelBooking: {},
  cancelBookingPending: false,
  cancelBookingError: null,

  // GENERAL
  error: null,
  pending: false,

  // PROPERTY FILTER
  filterProperty: null,
}

const getLocationsSuccess = (state: AppleStoreState, payload: { data: Array<Location> }) => ({
  ...state,
  locations: payload.data,
  filterProperty: null,
  getLocationsError: null,
})

// eslint-disable-next-line no-unused-vars
const searchPropertiesRequest = (state: AppleStoreState, payload: SearchPayload) => ({
  ...state,
  properties: [],
  searchPropertiesError: null,
})

const searchPropertiesSuccess = (state: AppleStoreState, payload: { data: Array<Property> }) => ({
  ...state,
  properties: Array.isArray(payload.data) ? payload.data : [],
  searchPropertiesError: null,
})

const setFilterProperty = (state: AppleStoreState, payload: any) => ({
  ...state,
  filterProperty: payload?.value === 'clear' ? null : payload,
})

const checkPriceSuccess = (state: AppleStoreState, payload: { data: PriceCheckDataType }) => ({
  ...state,
  priceCheck: payload.data,
  priceCheckError: null,
})

const createBookingSuccess = (
  state: AppleStoreState,
  payload: { data: CreateBookingDataType },
) => ({
  ...state,
  createBooking: payload.data,
  createBookingError: null,
})

const payBookingSuccess = (
  state: AppleStoreState,
  payload: { data: any },
) => ({
  ...state,
  payBooking: payload.data,
  payBookingError: null,
})

const checkItinerarySuccess = (
  state: AppleStoreState,
  payload: { data: CheckItineraryDataType },
) => ({
  ...state,
  checkItinerary: payload.data,
  checkItineraryError: null,
})

const cancelBookingSuccess = (
  state: AppleStoreState,
  payload: { data: CancelBookingDataType },
) => ({
  ...state,
  cancelBooking: payload.data,
  cancelBookingError: null,
})

export const reduxSet = {
  startRequest: dispatchReducer<AppleStoreState, { [string]: ?null }>(
    ACTIONS.START_REQUEST,
    startRequest,
  ),
  endRequest: dispatchReducer<AppleStoreState, ?{ [string]: ?Error }>(
    ACTIONS.END_REQUEST,
    endRequest,
  ),
  // EXPEDIA
  getLocationsSuccess: dispatchReducer<AppleStoreState, { data: Array<Location> }>(
    ACTIONS.GET_LOCATIONS_SUCCESS,
    getLocationsSuccess,
  ),
  searchPropertiesRequest: dispatchReducer<AppleStoreState, SearchPayload>(
    ACTIONS.SEARCH_PROPERTIES_REQUEST,
    searchPropertiesRequest,
  ),
  searchPropertiesSuccess: dispatchReducer<AppleStoreState, { data: Array<Property> }>(
    ACTIONS.SEARCH_PROPERTIES_SUCCESS,
    searchPropertiesSuccess,
  ),
  checkPriceRequest: dispatchReducer<AppleStoreState, CheckPricePayload>(
    ACTIONS.CHECK_PRICE_REQUEST,
    noOp,
  ),
  setFilterProperty: dispatchReducer<AppleStoreState, any>(
    ACTIONS.FILTER_PROPERTY,
    setFilterProperty,
  ),
  checkPriceSuccess: dispatchReducer<AppleStoreState, { data: PriceCheckDataType }>(
    ACTIONS.CHECK_PRICE_SUCCESS,
    checkPriceSuccess,
  ),
  createBookingRequest: dispatchReducer<AppleStoreState, CreateBookingPayload>(
    ACTIONS.CREATE_BOOKING_REQUEST,
    noOp,
  ),
  createBookingSuccess: dispatchReducer<AppleStoreState, { data: CreateBookingDataType }>(
    ACTIONS.CREATE_BOOKING_SUCCESS,
    createBookingSuccess,
  ),
  payBookingRequest: dispatchReducer<AppleStoreState, PayBookingRequest>(
    ACTIONS.PAY_BOOKING_REQUEST,
    noOp,
  ),
  payBookingSuccess: dispatchReducer<AppleStoreState, { data: any }>(
    ACTIONS.PAY_BOOKING_SUCCESS,
    payBookingSuccess,
  ),
  checkItineraryRequest: dispatchReducer<AppleStoreState, { itineraryId: string }>(
    ACTIONS.CHECK_ITINERARY_REQUEST,
    noOp,
  ),
  checkItinerarySuccess: dispatchReducer<AppleStoreState, { data: CheckItineraryDataType }>(
    ACTIONS.CHECK_ITINERARY_SUCCESS,
    checkItinerarySuccess,
  ),
  cancelBookingRequest: dispatchReducer<AppleStoreState, { itineraryId: string }>(
    ACTIONS.CANCEL_BOOKING_REQUEST,
    noOp,
  ),
  cancelBookingSuccess: dispatchReducer<AppleStoreState, { data: CancelBookingDataType }>(
    ACTIONS.CANCEL_BOOKING_SUCCESS,
    cancelBookingSuccess,
  ),
}

export const reducer = (
  state: AppleStoreState = { ...INITIAL_STATE },
  action: StandardAction,
): AppleStoreState => runReducers(state, reduxSet, action)
