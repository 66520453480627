// @flow

import React from 'react'
import LogRocket from 'logrocket'
import { withRouter } from 'react-router'
import type { ContextRouter } from 'react-router'
import Raven from 'raven-js'
import { Navbar, Footer, Version, CookieBanner } from '@/components'
import { tracker } from '@/helpers/ga'
import hotjar from '@/helpers/hotjar'
import styles from '@/../styles/main.scss'
import config from '../config'

type Props = {|
|}

type AppProps = {| children: any, ...Props, ...ContextRouter |}

export class App extends React.Component<AppProps> {
  componentDidMount() {
    const { sentry, logrocket } = config
    if (sentry && process.env.NODE_ENV !== 'development') {
      Raven.config(sentry.token).install()
      Raven.setDataCallback((data: { extra: { sessionURL: string } }) => {
        const newData = { ...data }
        newData.extra.sessionURL = LogRocket.sessionURL
        return newData
      })
    }
    if (logrocket && process.env.NODE_ENV !== 'development') {
      LogRocket.init(logrocket.url, {
        shouldShowReportingButton: logrocket.show_reporting_button,
        sha: config.sha,
        network: {
          requestSanitizer: request => {
            // Sanitize the Reservation Create Endpoint
            if (request.url.indexOf('ReservationCreateRequest') !== -1) {
              // scrub out the body
              if (request.body) {
                const body = JSON.parse(request.body)
                body.request.payment_detail.payment_card.card_number = null
                body.request.payment_detail.payment_card.card_code = null
                request.body = JSON.stringify(body)
              }
            }

            return request
          },
        },
      })
      // Start session
      LogRocket.startNewSession()
    }
    if (config.hotjar) {
      hotjar.initialize(config.hotjar.id, config.hotjar.version)
    }
    // Google Analytics
    tracker.initialize()
  }

  render() {
    const { children } = this.props
    return (
      <div className={styles.app}>
        <Navbar />
        {children}
        <Footer />
        <Version />
        {/* <CookieBanner /> The cookie banner already exists on the WIX website */}
      </div>
    )
  }
}

export default withRouter<*>(App)
